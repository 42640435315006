<template>
  <div class="color ">
      <div class="top  container-fluid centrado">
        <div class=" row text-center">
            <div class="col-md-4 offset-md-4 ">
                <div class="fondo-login sombra">
                    <div class="titulo">
                        <div class="text-align-center">
                            <!-- <imkg class="img" src="../assets/logo_T2.png" alt=""> -->
                            <br>
                        <h1 class="mt-5 color_titulo"><b> Inicio de sesión</b></h1><br>
                        </div>
                    </div>
                    <div class="input mb-3 mt-4">
                        <input type="text" v-model="username" class=" form-control tamaño-input sombra-login p-4 bg-light rounded" placeholder="usuario" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                    <div class="input mb-3">
                        <input type="password" v-model="password" class=" form-control tamaño-input sombra-login p-4 bg-light rounded" placeholder="contraseña" aria-label="whatsapp" aria-describedby="basic-addon1" >
                    </div>
                    
                    <button class="btn color-button mt-3" @click="login">Iniciar sesión</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            username: '',
            password: ''
        }
    },
    methods:{
        async login(){
            try {
                let methodRequest
                let msg
                let path
                methodRequest= 'post'
                path = 'login'
                let options = {
                method: methodRequest,
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({username: this.username, password: this.password})
                }
                let req = await fetch(this.$store.state.url + path, options)
                let res = req.json()
                console.log(req)
                console.log(res)
                if (req.ok) {
                    //alert(req.)
                    //this.$store.commit('updateUserList', true);
                    this.$router.push('formwizard')
                }else{
                    alert('Error, intentalo nuevamente');
                }
            }
            catch(err){
                console.log(err)
                alert('Error, intentalo nuevamente');
            }
        }
    }
}
</script>


<style>
.color{
    background-color: rgba(255, 255, 255, 0.904);
    height: 765px;
}
.top{
    margin-top: 1%;
}
.fondo-login{
    background-color: rgba(255, 255, 255, 0.739);
    width: 100%;
    border-radius: 30px;
    
    
}
.tamaño-input{
    width:60%;
    margin-left: 20%;
    margin-right: 15%;
    
}
.img{
    max-width: 60%;
    max-height: 60%;
    margin-top: 5%;
    
}
.color-button{
      background-color: rgb(8, 19, 116);
      color: rgb(242, 212, 251);
      border-radius: 10px;
      width: 300px;
      height: 50px;
      font-size: 25px;
      margin-bottom: 30%;
      
  }
  .sombra-login{
      box-shadow: 0 5px 5px 0 #13131332
    }
    .color_titulo{
        color: rgb(8, 19, 116);
    }
</style>